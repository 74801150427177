
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { IManipulationType, IManipulationFilter, IManipulationSearch } from '../types';
import { ITableHeader, IPagination, ISimpleDataItem } from '../../system/types';

@Component
export default class ManipulationsView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;
  showGroups = false;

  showGroupEdit = false;
  group: ISimpleDataItem | null = null;

  groups: Array<ISimpleDataItem> = [];
  filter: IManipulationFilter = $app.clone(this.clinic.$store.ManipulationSearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: IManipulationType | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: '',
        value: 'ManipulationTypeActive',
        align: 'left',
        width: '5%'
      },
      {
        text: $app.i18n('dplus.manipulations.Code'),
        value: 'ManipulationCode',
        align: 'left',
        width: '5%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'ManipulationTypeName',
        align: 'left',
        width: '40%'
      },
      {
        text: $app.i18n('dplus.manipulations.Group'),
        value: 'ManipulationGroupName',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('dplus.manipulations.Price'),
        value: 'ManipulationPrice',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.Lab'),
        value: 'ManipulationLab',
        align: 'right',
        width: '10%'
      }
    ];
  }

  get search(): IManipulationSearch {
    return this.clinic.$store.ManipulationSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findManipulations(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.ManipulationSearch, this.filter);
    this.clinic.$store.setManipulationSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IManipulationType) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeManipulation(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IManipulationType) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeManipulation(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IManipulationType) {
    this.selectedItem = item;
    this.showItem = await this.reloadGroups();
  }

  async append() {
    this.selectedItem = {
      id: null,
      code: 'CODE',
      name: 'NEW MANIPULATION',
      group: null,
      groupName: null,
      price: 0,
      state: 'a',
      nvd: null,
      priceNVD: 0,
      surfaces: 0,
      lab: false,
      linked: false,
      active: true,
      duration: null,
      processing: false,
      processed: false
    };
    this.showItem = await this.reloadGroups();
  }

  async openGroups() {
    this.showGroups = await this.reloadGroups();
  }

  async reloadGroups(): Promise<boolean> {
    let result = false;
    this.loading = true;
    try {
      this.groups = await $app.get('/api/clinics/manipulations/groups');
      result = true;
    } catch (error) {
      $app.pushError(error)
    }
    this.loading = false;
    return result;
  }

  openGroup(item: ISimpleDataItem) {
    this.group = item;
    this.showGroupEdit = true;
  }

  async addGroup() {
    this.loading = true;
    try {
      let item: ISimpleDataItem = {
        id: null,
        code: '',
        name: 'NEW GROUP',
        descr: '',
        processing: false
      }
      item = await $app.post('/api/clinics/manipulations/groups', item)
      this.groups = [item].concat(this.groups);
    } catch (error) {
      $app.pushError(error)
    }
    this.loading = false;
  }

  async storeGroup(item: ISimpleDataItem) {
    item = await $app.post('/api/clinics/manipulations/groups', item);
    this.group!.id = item.id;
    this.group!.code = item.code;
    this.group!.name = item.name;
    this.group!.descr = item.descr;
    this.showGroupEdit = false;
  }

  async removeGroup(index: number) {
    const item = this.groups[index];
    if (item) {
      item.processing = true;
      await $app.delete('/api/clinics/manipulations/groups/' + item.id);
      this.groups.splice(index, 1);
    }
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
